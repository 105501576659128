#root {
  padding: calc(43px + 1em) 1em 1em;
}

.logo-background {
  width: 100vw;
  height: 90vh;
  opacity: .15;
  z-index: -1;
  background-image: url("logo.2b67d321.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 80%;
  position: absolute;
  top: 0;
  left: 0;
}

footer {
  width: 100%;
  float: bottom;
  padding: 1em 0;
}

.contact, .copyright {
  width: 40vw;
}

.copyright {
  float: left;
  margin-top: 1em;
}

.contact {
  float: right;
  text-align: right;
}

.daysOfWeek {
  margin-right: 1em;
}

.netlify-identity-item {
  padding-right: 1em;
}

.netlify-identity-item a {
  color: #000000de;
}

.netlify-identity-menu .netlify-identity-item {
  display: inline;
}

.logo-font {
  font-family: Pacifico, cursive !important;
  font-size: 2em !important;
  font-weight: 200 !important;
}

.large-font-size {
  font-size: 1.2em;
}

.large-padding-top {
  padding-top: 1em !important;
}

.notice {
  margin-bottom: 1em;
}

.sauce-qty {
  width: 250px !important;
}

.fb-page {
  margin-botton: 2em;
  margin-top: 3em;
  margin-left: 20vw;
}

@media (max-width: 750px) {
  .fb-page {
    margin-left: 0;
  }
}

/*# sourceMappingURL=index.84897f46.css.map */
